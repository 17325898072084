import classNames from 'classnames';
import { INVITATION_STATUS, SURVEY_STATE_VALUES, SURVEY_TAKING_STATUS, SURVEY_TAKING_STATUS_SENTENCE } from '../../lib/Enum';
import './StatusLabel.scss';

const COLORS = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  BLUE: 'blue',
  GREY: 'grey',
  ORANGE: 'orange',
  PURPLE: 'purple',
};

export const STATUS_LABEL_THEME = {
  OUTLINE: 'outline'
};

export const STATUS_LABEL_COLOR = {
  ...COLORS,
  SURVEY: {
    [SURVEY_STATE_VALUES.DRAFT]: COLORS.GREY,
    [SURVEY_STATE_VALUES.PENDING]: COLORS.YELLOW,
    [SURVEY_STATE_VALUES.ACTIVE]: COLORS.GREEN,
    [SURVEY_STATE_VALUES.CLOSED]: COLORS.RED,
  },
  SURVEY_TAKING: {
    [SURVEY_TAKING_STATUS.COMPLETED]: COLORS.GREEN,
    [SURVEY_TAKING_STATUS.IN_PROGRESS]: COLORS.YELLOW,
    [SURVEY_TAKING_STATUS.NOT_STARTED]: COLORS.RED,
    [SURVEY_TAKING_STATUS.UNSUBSCRIBED]: COLORS.PURPLE,
  },
  PARTICIPANT_STATUS: {
    [SURVEY_TAKING_STATUS_SENTENCE.COMPLETED]: COLORS.GREEN,
    [SURVEY_TAKING_STATUS_SENTENCE.IN_PROGRESS]: COLORS.YELLOW,
    [SURVEY_TAKING_STATUS_SENTENCE.NOT_STARTED]: COLORS.RED,
    [SURVEY_TAKING_STATUS_SENTENCE.UNSUBSCRIBED]: COLORS.PURPLE,
  },
  INVITATION: {
    [INVITATION_STATUS.NOT_APPLICABLE]: COLORS.GREY,
    [INVITATION_STATUS.PENDING]: COLORS.YELLOW,
    [INVITATION_STATUS.SENT]: COLORS.YELLOW,
    [INVITATION_STATUS.DEFERRED]: COLORS.ORANGE,
    [INVITATION_STATUS.BOUNCE]: COLORS.RED,
    [INVITATION_STATUS.DROPPED]: COLORS.RED,
    [INVITATION_STATUS.DELIVERED]: COLORS.GREEN,
  }
};

const StatusLabel = ({ children, color, style, theme }) => (
  <div
    style={style}
    className={
      classNames(
        'system-status-label',
        `system-status-label--${color}`,
        {
          [`system-status-label--theme-${theme}`]: theme
        }
      )
    }
    data-component={'status_label'}
  >
    {children}
  </div>
);

StatusLabel.defaultProps = {
  color: STATUS_LABEL_COLOR.GREY
};

export const SurveyStatusLabel = ({ survey_state, style }) => (
  <StatusLabel
    style={{
      textTransform: 'uppercase',
      ...style
    }}
    color={STATUS_LABEL_COLOR.SURVEY[survey_state]}
  >
    {survey_state}
  </StatusLabel>
);
SurveyStatusLabel.defaultProps = {
  survey_state: ''
};

export const SurveyTakingStatusLabel = ({ survey_status, style }) => (
  <StatusLabel
    style={{
      textTransform: 'uppercase',
      ...style
    }}
    color={STATUS_LABEL_COLOR.SURVEY_TAKING[survey_status]}
  >
    {survey_status}
  </StatusLabel>
);
SurveyTakingStatusLabel.defaultProps = {
  survey_status: ''
};

export default StatusLabel;
