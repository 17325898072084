import { SYSTEM_COLOR } from '_Lib/Enum';

const CountLabel = (props) => {
  const {
    count = 0,
    color,
    textColor = SYSTEM_COLOR.GREY.DARK,
    children
  } = props;

  return (
    <span>
      <span style={{ color, marginRight: 4, fontSize: 16, fontWeight: 500 }}>
        {count}
      </span>
      <span style={{ color: textColor, fontSize: 14, fontWeight: 300 }}>{children}</span>
    </span>
  );
};

export default CountLabel;
